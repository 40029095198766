
import { CustomerInfoResponse, Domain } from "@/types/responses"
const SelectPrimaryContact = () => import("@/components/customer/SelectPrimaryContact.vue").then(m => m.default || m)
import Vue from "vue"

// NB! For jest purposes. No actual impact on import.
/* eslint-disable */
// @ts-ignore
import { required, digits } from "vee-validate/dist/rules.umd.js"
/* eslint-enable */

import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate"
import { CompanyInfo } from "@/types/responses"


const isValidName = (name: string) => {
	/*const regExp = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9 ,.!?:;/'(){}&---+*÷=%]*$/g;
	if(!regExp.test(name)) {
		return false
	}
	*/
	return true;
}

const isValidDescription = (description: string) => {
	/*const regExp = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9 !@#$%^&*()_+=/\---{}'".,<>:;~`åæø]*$/g;
	if(!regExp.test(description)) {
		return false
	}
	*/
	return true;
}


extend("businessId", {
	...digits,
	message: "Business registry ID must be {length} digits in length",
})

extend("name", {
	validate: (value) => isValidName(value),
	message: "The name contains invalid characters",
})

extend("description", {
	validate: (value) => isValidDescription(value),
	message: "The description contains invalid characters",
})

extend("required", {
	...required,
	message: "This field cannot be empty",
})

export default Vue.extend({
	name: "CustomerSettings",
	components: {
		SelectPrimaryContact,
		ValidationProvider,
		ValidationObserver,
	},
	props: {
		clientPersons: {
			type: Boolean,
			required: true
		}
	},
	async mounted() {
		if(!this.hasFetchedInfo()){
			await this.refreshCustomerInfo();
		}

		this.loaded = true;

		if(this.hasFetchedInfo()){
			this.setFieldsEditableFields();
			this.setOrgFieldsEditableFields();
		}
	},
    data(): {
		snackBar: boolean
		snackBarText: string
		timeLeft: number

        loaded: boolean
		loading: boolean
		nameLoading: boolean
		descLoading: boolean
		bridLoading: boolean
		orgName: string
		orgDescription: string
		orgBusinessRegistryId: string
		newName: string
		newDescription: string
		newBusinessRegistryId: string

		bgField: string
    } {
        return {
			snackBar: false,
			snackBarText: "",
			timeLeft: 2,

            loaded: false,
			loading: false,
			nameLoading: false,
			descLoading: false,
			bridLoading: false,
			orgName: "",
			orgDescription: "",
			orgBusinessRegistryId: "",
			newName: "",
			newDescription: "",
			newBusinessRegistryId: "",

			bgField: "rgba(241, 241, 241, 0.65)"
        }
    },
	methods: {
		async refreshCustomerInfo(): Promise<void> {
			await this.$vStore.dispatch("context/getCustomerInfo")
		},
		getCustomerInfo(): CustomerInfoResponse | null {
			if(this.loaded) return this.$store.state.context.selectedCustomerInfo
			return {} as CustomerInfoResponse;
		},
		hasFetchedInfo(): boolean {
			// eslint-disable-next-line
			if(this.$store.state.context!.selectedCustomerInfo){
				if(this.$store.state.context!.selectedCustomerInfo!.id == this.$store.state.context!.selectedCustomer!.id) return true;
				return false
			} else {
				return false;
			}
		},
		async nameFieldEnter(): Promise<void> {
			if(this.newName == this.orgName) return
			const errors = await (this.$refs as any).customerObserver1.errors!.newName.length;
			if(errors > 0) return

			const newCompanyInfo: CompanyInfo = {
				name: this.newName,
				description: null,
				businessRegistryId: null
			}
			this.save(newCompanyInfo)			
		},
		async descFieldEnter(): Promise<void> {
			if(this.newDescription == this.orgDescription) return
			const errors = await (this.$refs as any).customerObserver2.errors!.newDescription.length;
			if(errors > 0) return
			
			const newCompanyInfo: CompanyInfo = {
				name: null,
				description: this.newDescription,
				businessRegistryId: null
			}
			this.save(newCompanyInfo)		
		},
		async bridFieldEnter(): Promise<void> {
			if(this.newBusinessRegistryId == this.orgBusinessRegistryId) return
			const errors = await (this.$refs as any).customerObserver3.errors!.newBusinessRegistryId.length;
			if(errors > 0) return

			const newCompanyInfo: CompanyInfo = {
				name: null,
				description: null,
				businessRegistryId: this.newBusinessRegistryId
			}
			this.save(newCompanyInfo)		
		},
		async countDownTimer(): Promise<void> {
			this.timeLeft = 2
			this.snackBarText = `Successfully updated company info.<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
			return new Promise((resolve) => {
				const uploadTimer = setInterval(() => {
					this.timeLeft--
					this.snackBarText = `Successfully updated company info.<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
					if (this.timeLeft <= 0) {
						clearInterval(uploadTimer)
						resolve()
					}
				}, 1000)
			})
		},
		setOrgFieldsEditableFields(): void {
			// eslint-disable-next-line
			this.orgName = this.getCustomerInfo()!.name
			// eslint-disable-next-line
			this.orgDescription = this.getCustomerInfo()!.description
			// eslint-disable-next-line
			this.orgBusinessRegistryId = this.getCustomerInfo()!.businessRegistryId
		},
		setFieldsEditableFields(): void {
			// eslint-disable-next-line
			this.newName = this.getCustomerInfo()!.name
			// eslint-disable-next-line
			this.newDescription = this.getCustomerInfo()!.description
			// eslint-disable-next-line
			this.newBusinessRegistryId = this.getCustomerInfo()!.businessRegistryId
		},
		disabled(): boolean {
			return false
		},
		async save(companyInfo: CompanyInfo): Promise<void> {
			if (this.loading) return
			this.loading = true
			try {
				if(companyInfo.name){ 
					this.nameLoading = true;
					await this.$vStore.dispatch("context/setCompanyName", companyInfo)
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.customerObserver1) { ;(this.$refs as any).customerObserver1.reset() }
					})
					this.orgName = this.newName;
				} else if (companyInfo.description) {
					// eslint-disable-next-line
					this.descLoading = true;
					await this.$vStore.dispatch("context/setCompanyDescription", companyInfo)
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.customerObserver2) { ;(this.$refs as any).customerObserver2.reset() }
					})
					this.orgDescription = this.newDescription;
				} else if (companyInfo.businessRegistryId){
					this.bridLoading = true;
					await this.$vStore.dispatch("context/setCompanyBusinessRegistryId", companyInfo)
					requestAnimationFrame(() => {
						// eslint-disable-next-line
						if (this.$refs.customerObserver3) { ;(this.$refs as any).customerObserver3.reset() }
					})
					this.orgBusinessRegistryId = this.newBusinessRegistryId;
				}
					
				// Lock loading for snackbar reload page
				this.snackBarText = `Successfully updated company info.<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
				this.snackBar = true
				await this.countDownTimer()

				if(companyInfo.name){
					await this.$vStore.dispatch("nav/updateModules")
					await this.$vStore.dispatch("context/updateDomains")
					const hit = await this.$store.state.context.domains.find((domain: Domain) => domain.id === this.$store.state.context!.selectedCustomer!.id)
					if (hit) {
						await this.$vStore.dispatch("context/setCustomer", hit)
					}
				}
				await this.refreshCustomerInfo()


				// Stop all loading
				this.nameLoading = false;
				this.descLoading = false;
				this.bridLoading = false;
				this.loading = false;
			} catch (e) {
				this.snackBarText = "Could not update company info.<br/><br/>Error: " + JSON.stringify(e)
				this.snackBar = true
				
				// Stop all loading
				this.nameLoading = false;
				this.descLoading = false;
				this.bridLoading = false;
				this.loading = false
			}
		},
	},
})
